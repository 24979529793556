<template>
  <!--begin::Main-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <!--begin::Aside-->
      <div
        class="d-none d-lg-block position-sticky top-0 left-0 bg-img-hero w-lg-400px w-xl-600px vh-100"
        style="background-image: url(assets/media/auth-bg.png)"
      />
      <!--end::Aside-->
      <!--begin::Body-->
      <div class="d-flex flex-column flex-lg-row-fluid py-10 bg-light min-vh-100">
        <!--begin::Content-->
        <div class="d-flex flex-center flex-column flex-column-fluid">
          <!--begin::Wrapper-->
          <div class="w-lg-500px p-10 p-lg-15 mx-auto">
            <a href="#">
              <img alt="Royal Stone Group Logo" src="assets/media/logo.png" class="logo-default h-45px" />
            </a>

            <router-view />
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
  <!--end::Main-->
</template>

<script>
export default {
  mounted() {
    document.body.classList.add('bg-body');
  },
  destroyed() {
    document.body.classList.remove('bg-body');
  },
};
</script>
